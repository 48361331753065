import React, { useState } from 'react';
import * as styles from "./SignUpBox.module.scss";
import * as globalStyle from "../../styles/common/Common.module.scss";

import { Button, Container, Row, Col, InputGroup } from "react-bootstrap";
import grayEmailIcon from "../../assets/images/homepage/grayEmailIcon.svg"

import sendEmail from "../../utils/sendEmail";

const SignUpBox = ({heading, subText, buttonText}) => {
  const [email, setEmail] = useState();
  const [alert, setShowAlert] = useState('');

  return (
    <>
      <section className={`${styles['mainSignUpBox']} hideOnStick`}>
        <Container className={styles['signup']}>
          <Row>
            <Col sm={12} md={10} lg={10} className="mx-auto text-center">
              <h2 className="fw-bold">{`${heading ? heading : 'Want to take Lucidity for a spin?'}`}</h2>
              <span className="d-block">{`${subText !==null ? subText : 'Signup below for early access'}`}</span>

              <form onSubmit={(e) => sendEmail(e, setShowAlert, email)}>
                <InputGroup className={styles['signup__wrapper']}>
                  {alert && (
                    <div className={`${globalStyle['globalAlert']} text-center alert alert-success 
            alert-dismissible fade show  mx-auto position-absolute` } role="alert">
                      Thank you, we'll get back to you shortly.
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert('')}></button>
                    </div>
                  )}

                  <img src={grayEmailIcon} alt={grayEmailIcon} className={`${styles['signup__email_icon']} position-absolute }`} />
                  <input type="email"
                    className={`${styles['signup__input']} form-control`}
                    placeholder="Enter Official email"
                    aria-label="email"
                    aria-describedby="email1"
                    name="user_email"
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Button type="submit" variant="secondary" className={`${styles['signup__accessbutton']} position-absolute fw-bold`}>
                  {`${buttonText ? buttonText : 'Get Early Access'}`}
                  </Button>
                  {alert === false && (
                    <label className={styles["signup__invaliEmail"]}>Please enter business email.</label>
                  )}
                </InputGroup>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SignUpBox
