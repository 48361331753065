import React, {useState, useEffect} from "react";
import Layout from "../components/Common/Layout";
import Seo from "../components/Common/Seo";
import Landing from "../components/LandingPage";
import SignUpBox from "../components/SignUpBox/SignUpBox";
import SignUpModel from "../components/Modals/SignUpModal";

const LandingPage = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 10000)
  }, [])

  return (
    <Layout cta="Download Ebook" setShow={setShow} hideNavLinks={true}>
      <Seo title="Supercharged Cloud Block Storage"/>

      <SignUpModel show={show} handleClose={handleClose}/>
      <Landing props={props}/>
      <SignUpBox 
      heading="Is your Storage Optimized?"
      subText='Sign up now to get a Free Storage Audit'
      buttonText="Sign Up" />

    </Layout>
  )
}

export default LandingPage;