
import React, { useState } from "react";
import { Modal, Button, InputGroup } from "react-bootstrap";

import * as globalStyle from "../../../styles/common/Common.module.scss";
import grayEmailIcon from "../../../assets/images/homepage/grayEmailIcon.svg"
import sendEmail from "../../../utils/sendEmail";
import * as styles from "../../SignUpBox/SignUpBox.module.scss";
import "./SignUpModal.scss";

const SignUpModel = ({ show, handleClose }) => {
  const [email, setEmail] = useState();
  const [alert, setShowAlert] = useState('');

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" 
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <div className="p-3 pb-4">
          <Modal.Title className="d-flex justify-content-end pe-2" onClick={handleClose}>
            &#x2715;
          </Modal.Title>
          <Modal.Body>
            <div className="text-center">
              <p>Download your Free copy of the ‘Block Storage Optimizer Ebook’</p>
              <p>We've helped a Logistics Unicorn save 70% of their storage budget with this secret weapon</p>
            </div>

            <div className="mt-4">
              <form onSubmit={(e) => sendEmail(e, setShowAlert, email)}>
                <InputGroup className={styles['signup__wrapper']}>
                  {alert && (
                    <div className={`${globalStyle['globalAlert']} text-center alert alert-success 
            alert-dismissible fade show  mx-auto position-absolute` } role="alert">
                      Excellent! You should find the Guide in your inbox any minute now.
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert('')}></button>
                    </div>
                  )}

                  <img src={grayEmailIcon} alt={grayEmailIcon} className={`${styles['signup__email_icon']} position-absolute }`} />
                  <input type="email"
                    className={`${styles['signup__input']} form-control`}
                    placeholder="Enter Official email"
                    aria-label="email"
                    aria-describedby="email1"
                    name="user_email"
                    required
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Button type="submit" variant="secondary" className={`${styles['signup__accessbutton']} position-absolute fw-bold`}>
                    Get Ebook
                  </Button>
                  {alert === false && (
                    <label className={styles["signup__invaliEmail"]}>Please enter business email.</label>
                  )}
                </InputGroup>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default SignUpModel;