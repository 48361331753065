import React, { useState, useRef, useEffect } from "react";
import { Carousel, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

import "./Testimonial.scss";
import nuwareVideo from "../../../assets/images/landingPage/nuware.mp4";
import nuwareThumbnail from "../../../assets/images/landingPage/nuware-thumbnail.png";

const Testimonial = () => {
  const [index, setIndex] = useState(0);
  const ref = useRef();

  const slides = (e) => {
    setIndex(e)
  }

  useEffect(() => {
    if (index !== 1) {
      ref.current.pause()
    }
  }, [index])

  const [next] = useState(<span><i className="fa fa-angle-right text-primary" aria-hidden="true"></i></span>)
  const [previous] = useState(<span><i className="fa fa-angle-left text-primary" aria-hidden="true"></i></span>)

  const videoControl = (e) => {
    if (ref.current.paused) {
      setTimeout(() => {
        ref.current.play()
      }, 120)
    } else {
      setTimeout(() => {
        ref.current.pause()
      }, 120)
    }
  }

  return (
    <>
      <Carousel nextIcon={next} prevIcon={previous}
        pause={'hover'} onSlid={slides}>
        {/* <Carousel.Item interval={3000}>
          <div className="testimonial mx-auto">
            <h3>Downtime during Surge</h3>
            <Row className="first-row">
              <Col lg={5} sm={12}>
                <div className="testimonial__logo mb-3">
                  <StaticImage className="testimonial__logo--pic" src="../../../assets/images/landingPage/rakesh.png" alt="rakesh" />
                  <div className="testimonial__name">
                    <p>Rakesh Singh, Director Infra, SC Johnson</p>
                    <StaticImage className="testimonial__name--company-logo" src="../../../assets/images/landingPage/sc-johnson-logo.png" alt="sc-johnson" />
                  </div>
                </div>
              </Col>
              <Col lg={7} sm={12}>
                <span>
                  "Capacity planning is hard. The biggest nightmare for a CIO is the system going down when you are sleeping. 
                  Lucidity Auto-scaler ensures that you never run out of IOPS/disk space as it has the capability to provision with 30-second notice."
                </span>
              </Col>
            </Row>
          </div>
        </Carousel.Item> */}

        <Carousel.Item interval={3000}>
          <div className="testimonial mx-auto">
            <h3>Making AWS storage smarter</h3>
            <Row>
              <Col lg={5} sm={12}>
                <div className="testimonial__logo testimonial__logo--second mb-3">
                  <StaticImage className="testimonial__logo--pic" src="../../../assets/images/landingPage/ajay.png" alt="ajay" />
                  <div className="testimonial__name">
                    <p>Ajay Jamble, Program Manager & CISO at NuWare</p>
                    <StaticImage className="testimonial__name--company-logo" src="../../../assets/images/landingPage/nuwarelogo.png" alt="nuware" />
                  </div>
                </div>
              </Col>
              <Col lg={7} sm={12}>
                <video width="100%" height={280} controls preload="none" poster={nuwareThumbnail} ref={ref} onClick={videoControl}>
                  <source src={nuwareVideo} type="video/mp4" />
                </video>
              </Col>
            </Row>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="testimonial mx-auto">
            <h3>Lucidity delivering High IOPS</h3>
            <Row className="first-row">
              <Col lg={5} sm={12}>
                <div className="testimonial__name mb-3">
                  <p>VP Infrastructure, Fintech Decacorn</p>
                </div>
              </Col>
              <Col lg={7} sm={12}>
                <span>
                  "Lucidity enables us to replace expensive IO2 disks with lucidity enabled GP3 disks.
                   It helps us save millions of dollars annually"
                </span>
              </Col>
            </Row>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Testimonial;

