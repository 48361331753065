
import React, { useState } from "react";
import {
  Button, Row, Col, InputGroup,
} from "react-bootstrap";

import blackEmail from "../../../assets/images/homepage/blackEmail.svg";

import * as styles from "../../../styles/Homepage/Homepage.module.scss";
import * as globalStyle from "../../../styles/common/Common.module.scss";
import sendEmail from "../../../utils/sendEmail";
import arrow from  "../../../assets/images/homepage/arrow.svg";

const HeroSignUp = ({ buttonText }) => {
  const [email, setEmail] = useState();
  const [alert, setShowAlert] = useState('');

  return (
    <Row className="mx-auto">
      <Col sm={12} md={11} lg={8} className="mx-auto">
        <form onSubmit={(e) => { sendEmail(e, setShowAlert, email) }}>
          <InputGroup className={styles['signup__wrapper']}>
            {alert && (
              <div className={`${globalStyle['globalAlert']} text-center alert alert-success 
        alert-dismissible fade show mx-auto position-absolute` } role="alert">
                Thank you, we'll get back to you shortly.
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setShowAlert('')}></button>
              </div>
            )}

            <img src={blackEmail} alt={blackEmail} className={`${styles['signup__email_icon']} position-absolute`} />
            <input type="email"
              className={`${styles['home_hero__input']} form-control`}
              placeholder="Enter Official email"
              aria-label="email"
              aria-describedby="email1"
              name="user_email"
              required
              onChange={e => setEmail(e.target.value)}
            />
            <Button type="submit" variant="primary" className={`${styles['signup__accessbutton']} position-absolute fw-bold`}>
              {`${buttonText ? buttonText : 'Get Early Access'}`}
              <img src={arrow} alt={arrow} className="d-inline ms-1" />
            </Button>
            {alert === false && (
              <label className={styles["signup__invaliEmail"]}>Please enter business email.</label>
            )}
          </InputGroup>
        </form>
      </Col>
    </Row>
  )
}

export default HeroSignUp;