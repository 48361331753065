import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";

import * as styles from "../../styles/Homepage/Homepage.module.scss";
import * as landingStyles from "./Landing.module.scss";
import { StaticImage } from "gatsby-plugin-image";

import gcp from "../../assets/images/landingPage/gcp.svg";
import azure from "../../assets/images/landingPage/azure.svg";
import aws from "../../assets/images/landingPage/aws.svg";

import HeroSignUp from "../SignUpBox/HeroSignUp";
import Testimonial from "./Testimonial";


const LucidityFeatures = ({ heading, subHeading, image }) => {
  return (
    <Row className={landingStyles['lucidity_features__row']}>
      <Col sm={6} className="pe-md-5 pe-3 pb-md-5 pb-3 my-auto">
        <h3>{heading}</h3>
        <span>{subHeading}</span>
      </Col>
      <Col sm={6} className="ps-md-5 ps-3 pb-md-5 pb-3">
        <div className={landingStyles['lucidity_features__img']}>
          {image}
        </div>
      </Col>
    </Row>
  )
}

const Landing = ({props}) => {
  const [currentCount, setCount] = useState(3);

  const timer = () => setCount(currentCount - 1);

  useEffect(() => {
    if (props.location.pathname === '/supercharged-block-storage/' || props.location.pathname === '/supercharged-block-storage') {
      document.getElementsByClassName('vc_custom_heading')[0].classList.remove('initialHide')

      if (currentCount <= 0) {
        setCount(3)
      }
      let id = setInterval(timer, 2400);

      return () => clearInterval(id);
    }
  }, [currentCount]);

  return (
    <>
      <section className={styles['heroBg']}>
        <Container className={`${styles['home_hero']} ${landingStyles['landing']}`}>
          <Row className="justify-content-center">
            <Col xs={12} lg={10} className="text-center mx-auto">
              <h1>Supercharged Cloud Block Storage</h1>
              <h2 className={`${styles['home_hero__subheading']} vc_custom_heading cd-headline rotate-1 initialHide`}>
                Give your database a boost<br></br>
                <span id="early-access-landing" className="cd-words-wrapper-landing border-bottom-spacings">
                    <b className={`${currentCount === 3 ? 'is-visible' : 'is-hidden'}`}>10x Performance</b>
                    <b className={`${currentCount === 2 ? 'is-visible' : 'is-hidden'} `}>Zero downtime</b>
                    <b className={`${currentCount === 1 ? 'is-visible' : 'is-hidden'} `}>70% reduced cost</b>
                  </span>
              </h2>

              <span className={landingStyles['landing__signup_text']}>
                Signup now to get a <span className={landingStyles['landing__signup_freeText']}>&nbsp;Free&nbsp; </span> Storage Audit
              </span>
            </Col>
          </Row>


          <HeroSignUp buttonText='Sign Up'/>

          <Row className={`${styles['home_hero__pros']} ${landingStyles['landing__cloud_logo']}`}>
            <Col sm={10} className={`${styles['home_hero__pros__prosLines']} mx-auto d-flex flex-md-row flex-column justify-content-center`}>
              <div className="d-flex  me-5">
                <img src={aws} alt="aws" height={60} width={120} />
              </div>

              <div className="d-flex me-5">
                <img src={azure} alt="azure" height={60} width={120} />
              </div>

              <div className="d-flex me-5">
                <img src={gcp} alt="gcp" height={60} width={120} />
              </div>

            </Col>
          </Row>
        </Container>
      </section>

      <section className={landingStyles['lucidity_features']}>
        <Container>
          <Row>
            <Col sm={12} className="mx-auto text-center mt-3">
              <h2>The Lucidity Leverage</h2>
            </Col>
          </Row>

          <LucidityFeatures
            heading="10x Performance"
            subHeading="Replace High-performance disks (Io2) with Lucidity-enabled GP3 disks.
             Get 10x IOPS at fraction of the cost."
            image={<StaticImage src={`../../assets/images/landingPage/one.png`} alt={`../../assets/images/landingPage/one.png`} />}
          />

          <LucidityFeatures
            heading="No downtime during Surge"
            subHeading="Lucidity auto-scaler automatically provisions disks, IOPS with 30 seconds’ notice during a 
            traffic surge with no downtime & performance loss."
            image={<StaticImage src={`../../assets/images/landingPage/two.png`} alt={`../../assets/images/landingPage/two.png`} />}
          />

          <LucidityFeatures
            heading="Reduce block storage cost by 70%"
            subHeading="Auto-Scaler, state-of-the-art compression & deduplication ensures storage is optimized."
            image={<StaticImage src={`../../assets/images/landingPage/three.png`} alt={`../../assets/images/landingPage/three.png`} />}
          />

          <LucidityFeatures
            heading="Onboarding to ROI in 2 clicks"
            subHeading="Autonomous orchestration delivers a truly NoOps experience. So sit back and let Lucidity handle your block storage."
            image={<StaticImage src={`../../assets/images/landingPage/four.png`} alt={`../../assets/images/landingPage/four.png`} />}
          />

          <LucidityFeatures
            heading="Run any workload on Lucidity"
            subHeading="From databases like MySQL and Postgres to hosting Kubernetes clusters, lucidity delivers seamless performance."
            image={<StaticImage src={`../../assets/images/landingPage/five.png`} alt={`../../assets/images/landingPage/five.png`} />}
          />

        </Container>
      </section>

      {/* Testimonial */}
      <section className={landingStyles['testimonials']}>
        <Container>
          <Row>
            <Col sm={12} className="text-center">
              <h2>Customer Speaks</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Testimonial />
            </Col>
          </Row>
        </Container>
      </section>

      {/* dashboard */}
      <section className={`${styles['dashboard']} hideOnStick`}>
        <Container>
          <Row>
            <Col sm={12} md={10} lg={8} className="mx-auto text-center">
              <h2 className="mb-4">Get the most out of your Block Storage</h2>
            </Col>
          </Row>

          <Row>
            <Col sm={11} className="mx-auto d-flex">
              <StaticImage src={`../../assets/images/homepage/dashboard.png`} alt={`../../assets/images/homepage/dashboard.png`} className="pt-0 pt-md-4 mx-auto" />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Landing;